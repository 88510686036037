import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './IntroVideo.css';
import TseLogo from '../images/tse_logo2.png';
// import phlBackground from '../images/phl_background.png';
// import fredBackground from '../images/fred_background.png';
// import sjBackground from '../images/sj_background.png';
// import baltiBackground from '../images/balti_background.png';

// const backgrounds = [phlBackground, fredBackground, sjBackground, baltiBackground];

// const rndm = Math.floor(Math.random() * 3) + 1;

export default class IntroVideo extends Component {
  render() {
    return (
      <div className="videoBackground">
        <img
          src={this.props.backgroundImage}
          className="videoBackgroundImage"
        />
        <div className="videoContainer">
          <img
            src={TseLogo}
            className="videoLogo"
          />
          <div className="embeddedVideo">
            <ReactPlayer
              url="https://youtu.be/RbY-KSUD3ik"
              width="360px"
              height="270px"

            />
          </div>

          <div className="enterButton">
            <button onClick={this.props.closeVideo}>
              {this.props.buttonText}

            </button>
          </div>

        </div>
      </div>

    );
  }
}
