import React, { Component } from 'react';
import './Legend.css';
import CptrLogo from '../images/cptr_logo.png';
import TseLogo from '../images/tse_logo2.png';
import infoTooltip from '../images/info-tooltip.png';

export class Legend extends Component {
  render() {
    return (
      <div>
        <div
          className="titleBar"
        >
          <img
            src={CptrLogo}
            className="logo"
            alt="CPTR Logo"
          />
          <a href="/#/menu">
          <img
            src={TseLogo}
            className="tseLogo"
            alt = "TSE Logo"
            />
          </a>


        </div>

        <div id="legend">
          <div className="title_container">
            <h4 className="title">
              Change in
              <br />
              Median Tax Bill
            </h4>
            <div>
              <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="median_tax_tooltip"
                />
                <span className="tooltiptext">
                  The map at city - level view shows the difference in the median household
                  tax bill as a dollar difference from the current scenario.
                  <br />
                  <br />
                  Use the slider at the bottom of the page to show the projected difference in
                  median tax bill based on how much of the city's property revenue comes from a
                  tax on land value.
                  <br />
                  <br />
                  Click on each analysis zone
                  for more information.
                </span>
              </div>
            </div>
          </div>
          <div
            id="color_grad"
            className="legend_col"
          />
          <div className="legend_col">
            <div className="row">
              <p>
                +$1000
              </p>
            </div>

            <div className="row">
              <p>
                +$500
              </p>
            </div>

            <div className="row">
              <p>
                +$300
              </p>
            </div>

            <div className="row">
              <p>
                +$100
              </p>
            </div>
            <div className="row">
              <p>
                +$50
              </p>
            </div>

            <div className="row">
              <p>
                $0
              </p>
            </div>
            <div className="row">
              <p>
                -$50
              </p>
            </div>
            <div className="row">
              <p>
                -$100
              </p>
            </div>
            <div className="row">
              <p>
                -$300
              </p>
            </div>

            <div className="row">
              <p>
                -$500
              </p>
            </div>

            <div className="row">
              <p>
                -$1000
              </p>
            </div>

            <div className="row">
              <p>
                No Data
              </p>
            </div>
          </div>
          <div id="noData" />
          <div
            id="parcel_title"
            className="title_container"
          >
            <h4 className="title">
              Parcel Types
            </h4>
            <div>
              <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_tooltip"
                />
                <span className="tooltiptext">
                  Zoom in to street level to see individual parcels.
                  <br />
                  <br />
                  Click on a parcel to see how each projected tax scenario
                  affects a property's tax bill.
                </span>
              </div>
            </div>
          </div>
          <div id="parcel_legend">
            <div className="p_row">
              <div className="dot residential" />
              <p className="parcel_text">
                Residential
                <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                  Used exclusively for residential purposes.
                  <br />
                  <br />
                  Can be single unit, building, apartment, or multiple buildings.
                </span>
              </div>
              </p>  
            </div>
            
            <div className="p_row">
              <div className="dot commercial" />
              <p className="parcel_text">
                Commercial
                <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                  Land designated for businesses, shops, and infrastructure related to commerce.
                  <br />
                  <br />
                  Examples: store, restaurant, hotel, bank.
                </span>
              </div>
              </p>
            </div>

            <div className="p_row">
              <div className="dot mixed_use" />
              <p className="parcel_text">
                Mixed Use
                <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                  Contains two or more major types of land use.
                  <br />
                  <br />
                  Typically residential, commericial, and/or industrial.
                </span>
              </div>
              </p>
            </div>

            <div className="p_row">
              <div className="dot industrial" />
              <p className="parcel_text">
                Industrial
                <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                  Contains manufacturing, transportation, construction companies, communications, utilities, or wholesale trade.
                </span>
              </div>
              </p>
            </div>

            <div className="p_row">
              <div className="dot vacant" />
              <p className="parcel_text">
                Vacant Land
                <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                  Land in an undeveloped state, not used or occupied for any purpose.
                </span>
              </div>
              </p>
            </div>

            <div className="p_row">
              <div className="dot fully_exempt" />
              <p className="parcel_text">
                Exempt Parcels
                <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                  Parcel that is statutorily exempt from land and improvement taxes.
                  <br />
                  <br />
                  May still be assessed for land and improvement values.
                </span>
              </div>
              </p>
            </div>

              <div className="p_row">
                <div className = "dot other"/>
                <p className="parcel_text">
                  Other
                  <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                  Does not fall into one of the land use types identified in this legend.
                </span>
              </div>
                </p>
              </div>

            <div className="p_row">
              <div id="exempt_dot" />
              <p className="parcel_text">
                No Assessed Value
                <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                  The tax rolls show that this parcel was assessed at $0 for both land and improvement values.
                </span>
              </div>
              </p>
            </div>

            <div className="p_row">
              <div id="abated_dot" />
              <p className="parcel_text">
                No Assessed
                <div className="tooltip">
                <img
                  src={infoTooltip}
                  alt="Info Tooltip"
                  id="parcel_type_tooltip"
                />
                <span className="tooltiptext">
                The tax rolls show that this parcel was assessed at $0 for improvement value.
                </span>
              </div>
                <br />
                Building Value
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
