import React, { PureComponent } from 'react';
import sjBackground from '../images/sj_background.png';
import IntroVideo from '../components/IntroVideo';
import { withRouter } from 'react-router-dom';

class IntroView extends PureComponent {

  closeVideo = () => {
    this.props.history.push('/menu')
  };


  render() {
    return (
      <IntroVideo
        backgroundImage={sjBackground}
        buttonText="Enter"
        closeVideo={this.closeVideo}
      />
    );
  }
}

export default withRouter(IntroView)