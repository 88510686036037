import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import GridMenu from '../components/GridMenu.js';
import sjBackground from '../images/sj_background.png';


class MenuView extends PureComponent {
  render() {
    return (
      <GridMenu
        backgroundImage = {sjBackground}
      />
    );
  }
}

export default withRouter(MenuView);
