import React, { Component } from 'react';
import { LvtMap } from '../components/LvtMap';

export default class LvtMapView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      selectedCityIndex: null,
      shouldUpdatePermalink: true,
      taxRates: null,
      demographics: null,
      report: [],
    };
  }

  async componentDidMount() {
    const data = await fetch('https://rsf-2020-04-11.s3.us-east-2.amazonaws.com/catalog.json')
      .then((response) => response.json());

    const defaultCityName = this.props.match.params.defaultCityName;
    const defaultCityIdx = data.cityCollection.findIndex(it => it.name.toLowerCase() === defaultCityName.toLowerCase());

    this.setState({
      cities: data.cityCollection,
      selectedCityIndex: defaultCityIdx,
    });
    const selectedCity = data.cityCollection[defaultCityIdx];
    this.fetchCityData(selectedCity)
      .then();
  }

  async fetchCityData(selectedCity) {
    const taxRateUrl = selectedCity.data.split_rate;
    const taxRates = await fetch(taxRateUrl)
      .then((response) => response.json());
    this.setState({
      taxRates: taxRates.properties,
    });

    const demographicsUrl = selectedCity.data.demographics;
    const demographics = await fetch(demographicsUrl)
      .then((response) => response.json());
    this.setState({
      demographics: demographics.features,
    });

    const cityReportUrl = selectedCity.data.city_report;
    const cityReport = await fetch(cityReportUrl)
      .then((response) => response.url);
    this.setState({
      report: cityReport,
    });
  }

  handleCityChange = async (selectedIndex) => {
    this.setState({ selectedCityIndex: selectedIndex });
    const selectedCity = this.state.cities[selectedIndex];
    this.fetchCityData(selectedCity).then();
    this.updatePermalink(selectedIndex);
  };

  updatePermalink = (selectedCityIdx) => {

    if (!this.state.shouldUpdatePermalink) {
      this.setState({
        shouldUpdatePermalink: true
      });
      return;
    }

    const cityName = this.state.cities[selectedCityIdx].name;
    const prevUrlHash = window.location.hash;
    const splittedHash = prevUrlHash.split('/');
    splittedHash[splittedHash.length - 1] = cityName;
    const newUrlHash = splittedHash.join('/');
    window.history.pushState({}, window.location.title, newUrlHash);
  };

  render() {
    const selectedIndex = this.state.selectedCityIndex;
    const selectedCity = selectedIndex !== null ? this.state.cities[selectedIndex] : null;
    if (!selectedCity) {
      return <div> Loading... </div>;
    }

    return (
      <LvtMap
        cities={this.state.cities}
        taxRates={this.state.taxRates}
        demographics={this.state.demographics}
        report={this.state.report}
        selectedCityIndex={this.state.selectedCityIndex}
        onCityChange={this.handleCityChange}/>
    );
  }
}
