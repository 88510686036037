import React, { Component } from 'react';
import './GridMenu.css';
import './IntroVideo.css';
import sjBackground from '../images/sj_background.png';
import TseLogo from '../images/tse_logo2.png';

export default class GridMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      selectedIndex: null,
    };
  }

  async componentDidMount() {
    const data = await fetch('https://rsf-2020-04-11.s3.us-east-2.amazonaws.com/catalog.json')
      .then((response) => response.json());

    this.setState({
      cities: data.cityCollection,

    });
  }

  render() {
    return (

      <div className="videoBackground">
        <img
          src={this.props.backgroundImage}
          className="videoBackgroundImage"
          alt="intro video background"
        />
        <div className="cityViewContainer">
          <img
            src={TseLogo}
            className="cityViewLogo"
            alt="Tax Shift Explorer Logo"
          />
          <div className="cityViewHeader">
            <u>Select City</u>
          </div>
          <div className="cityItemContainer">

            <div className="cityViewItem">
              {this.state.cities.map((city, index) => (
                <div className="cityViewItem" key={`city-option${index}`}>
                  <h2>
                    <a href={`/#/map/${city.name}`}>
                      {city.data.displayName}
                      ,
                        {city.data.stateAbv}
                    </a>
                  </h2>
                  <div className="cityItemSubtitle">
                    <b>Analysis Zones:</b>
                    {' '}
                    {city.data.choroplethType}
                    <br />
                    <b>Revenue Share from Land Tax:</b>
                    {' '}
                    {city.data.actual_perc_rev_from_land}
                  </div>

                </div>
              ))}
            </div>
          </div>

        </div>

      </div>

    );
  }
}
