import React, { Component } from 'react';
import './CityMenu.css';

export class CityMenu extends Component {
    static defaultProps = {
      onChange: null,
      cities: [],
      selectedIndex: null,
    }

    handleChange = (selectedIndex) => {
      if (this.props.onChange) {
        this.props.onChange(selectedIndex);
      }
    };

    render() {
      const selectedIndex = this.props.selectedIndex || 0;
      const selectedCity = this.props.cities.length > 0 ? this.props.cities[selectedIndex] : null;
      return (
        <div>
          <div id="city_menu_container">
            <div id="city_title">
                {selectedCity && selectedCity.data.displayName}</div>

            <div id="subtitle">
              Projections for a Two-Rate Tax System by
              <br />
              {selectedCity.data.choroplethType}
              <br />
              Actual Percent Revenue from Land Tax: {selectedCity.data.actual_perc_rev_from_land}
            </div>
            <div className="dropdown">
              <button className="dropbtn">Select City</button>
              <div className="dropdown-content">
                {this.props.cities.map((city, index) => (
                  <div key={`city-option${index}`}>
                    <button
                      onClick={() => this.handleChange(index)}
                    >
                      {city.data.displayName}, {city.data.stateAbv}
                    </button>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      );
    }
}
