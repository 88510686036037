import React, { Component } from 'react';
import './BottomBar.css';

export default class BottomBar extends Component {
  render() {
    return (
      <div className="bottomBar">
        <div className="rsfLink">
          <a href="https://schalkenbach.org" target="_blank" rel="noopener noreferrer">
            Robert Schalkenbach Foundation
          </a>
        </div>
        <div className="cptrLink">
          <a href="https://centerforpropertytaxreform.org" target="_blank" rel="noopener noreferrer">
            Center for Property Tax Reform
          </a>
        </div>
        <div className="userLink">
          <a href="https://rsf-public-reports.s3.us-east-2.amazonaws.com/Tax_Shift_Explorer_User_Guide.pdf" target="_blank" rel="noopener noreferrer">
            User Guide
          </a>
        </div>
        <div className="sourcesLink">
          <a href="https://schalkenbach.org" target="_blank" rel="noopener noreferrer">
            Sources
          </a>
        </div>
      </div>

    );
  }
}
