import React from 'react';
import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
import IntroView from './views/IntroView';
import LvtMapView from './views/LvtMapView';
import MenuView from './views/MenuView';


function App() {
  return (
    <main>
      <HashRouter>
        <Switch>
          <Route exact path="/map/:defaultCityName" component={LvtMapView}/>
          <Route exact path="/" component={IntroView}/>
          <Route exact path="/menu" component={MenuView}/>
        </Switch>
      </HashRouter>
    </main>
  );
}

export default App;
