import React, { Component } from 'react';
import { Layer } from 'react-mapbox-gl';
import mapboxgl from 'mapbox-gl';
import './ChoroplethLayer.css';

const NUM_FORMAT = new Intl.NumberFormat('en-US');

export default class ChoroplethLayer extends Component {
    state = {
      popup: null,
    }

    handleZoneClick = (e) => {
      const map = e.target;
      if (map.getZoom() >= 15) {
        return;
      }

      const { props } = this;

      const zoneProps = e.features[0].properties;
      const medianActual = zoneProps.lt_bt_actual;
      const scenarioTaxBill = (zoneProps[props.dataField] + medianActual);
      const percentDifference = Math.round((zoneProps[props.dataField] / medianActual) * 100);
      const typeText = props.choroplethType;

      const zoneDemographics = props.demographics.find((zone) => zone.properties.zone_name === zoneProps.zone_name).properties;

      const popupHtml = `
            <div class = "popup">
                
                <div class = "popupTitle">${typeText} : ${zoneProps.zone_name}</div>
                <div class = "sectionTitle">Median Tax Bill</div>
                    <div><strong>Actual Median Tax Bill:</strong></div>
                    <div>$${NUM_FORMAT.format(medianActual)}</div>
                    <div><strong>Median Tax Bill at ${props.layerId} Revenue from Land Tax:</strong></div>
                    <div>$${NUM_FORMAT.format(scenarioTaxBill)}</div>
                    <div><strong>Change from Actual Median:</strong></div>
                    <div>$ ${NUM_FORMAT.format(zoneProps[props.dataField])} (${percentDifference}%)</div>
                    
                    
                <div class = "sectionTitle">Population</div>
                    <div><strong>Total Population:</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.pop_tot)}</div>
                    <div><strong>Median Age:</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.med_age)}</div>
                    <div><strong>White/Caucasian</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.perc_white * 100)} %</div>
                    <div><strong>Black / African American</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.perc_black * 100)} %</div>
                    <div><strong>Hispanic or Latino</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.perc_hispanic * 100)} %</div>
                    <div><strong>Asian</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.perc_asian * 100)} %</div>
                    <div><strong>Two or More Races</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.perc_two_or_more * 100)} %</div>
 
                    

                <div class = "sectionTitle">Households</div>
                    <div><strong>Total Households</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.hh_total)}</div>
                    <div><strong>Median Income: </strong></div>
                    <div>$${NUM_FORMAT.format(zoneDemographics.hh_med_inc)}</div>
                    <div> <strong>Owner Occupied:</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.perc_owner_occupied * 100)} %</div>
                    <div> <strong>Renter Occupied:</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.perc_renter_occupied * 100)} %</div>
                    <div> <strong>Not Occupied:</strong></div>
                    <div>${NUM_FORMAT.format(zoneDemographics.perc_not_occupied * 100)} %</div>
                    
                    


            </div>    
            `;

      new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(popupHtml)
        .addTo(map);
    }

    render() {
      const { props } = this;
      return (
        <>
          <Layer
            id={props.layerId}
            type="fill"
            sourceId={props.sourceId}
            layout={
                {
                  visibility: props.visible ? 'visible' : 'none',
                }
            }
            paint={
                {
                  'fill-color': ['interpolate', ['linear'],
                    ['get', props.dataField],
                    -1000, '#810f7c',
                    -750, '#8856a7',
                    -500, '#8c96c6',
                    -250, '#9ebcda',
                    -100, '#bfd3e6',
                    -50, '#edf8fb',
                    0, '#fff',
                    50, '#ffffd4',
                    100, '#fee391',
                    250, '#fec44f',
                    500, '#fe9929',
                    750, '#d95f0e',
                    1000, '#993404'
                  ],
                  'fill-opacity': ['step', ['zoom'], 0.8, 15, 0],
                  'fill-outline-color': '#000',
                }
            }
            onClick={this.handleZoneClick}
            onMouseEnter={(e) => e.target.getCanvas().style.cursor = 'pointer'}
            onMouseLeave={(e) => e.target.getCanvas().style.cursor = ''}
          />
        </>
      );
    }
}
