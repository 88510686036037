import React, { Component } from 'react';
import './ChoroplethMenu.css';

export class ChoroplethMenu extends Component {
    static defaultProps = {
      onChange: null,
      choroplethLayers: [],
      selectedIndex: null,
    }

    handleChange = (selectedIndex) => {
      if (this.props.onChange) {
        this.props.onChange(selectedIndex);
      }
    };

    render() {
      return (
        <div>
          <div id="choropleth_menu_container">
            <h3
              id="title"
              style={
                    { marginTop: 0 }
                }
            >
              {' '}
              Toggle Land Use Type
              {' '}
            </h3>
            {' '}
            {
                this.props.choroplethLayers.map((choroLyr, index) => (
                  <div key={`choropleth-option${index}`}>
                    <label id="land_use">
                      <input
                        type="radio"
                        name="choropleth-layer"
                        value={choroLyr.id}
                        defaultChecked={index === 0}
                        onClick={
                        () => this.handleChange(index)
                    }
                      />
                      {' '}
                      {choroLyr.name}
                    </label>
                  </div>
                ))
            }
            {' '}

          </div>
        </div>
      );
    }
}
