import React, { Component } from 'react';
import './TutorialPopup.css';

export default class TutorialPopup extends Component {
  render() {
    return (
      <div className="modalContent">

        <div
          id="choroplethTip"
          className="tip arrow-choropleth"
        >
          Click on any of the analysis zones to show it's change in median tax bill
          and demographic information.
        </div>
        <div
          id="selectCityTip"
          className="tip arrow-selectCity"
        >
          Select the city you wish to view from the dropdown menu.
          <br />
          <br />
          Map will automatically zoom to selected city.
        </div>

        <div
          id="searchTip"
          className="tip arrow-search"
        >
          Search bar zooms into parcel level.
          <br />
          <br />
          Accepts addresses, proper place names, neighborhoods,
          and many commercial locations.
        </div>

        <div
          id="zoomTip"
          className="tip arrow-zoom"
        >
          Zoom in to reveal individual parcels.
        </div>

        <div
          id="landuseTip"
          className="tip arrow-landuse"
        >
          Only parcels matching the selected type are
          included in the "change in median tax bill" analysis.
        </div>

        <div
          id="sliderTip"
          className="tip arrow-slider"
        >
          Adjust the slider bar to show how changing the proportion of total real estate
          revenue that comes from land value versus building/improvement value affects
          median tax bills across the city.
          <br />
          <br />
          Starting position is set at the closest 10% interval to actual land tax rate.
        </div>

      </div>
    );
  }
}
