import React from 'react';
import { Layer } from 'react-mapbox-gl';

export default function ChoroplethLabels(props) {
  return (
    <Layer
      type="symbol"
      sourceId={props.sourceId}
      layout={{
        'text-field': ['get', 'zone_name'],
        'text-size': 16,
        'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 0,
        'text-justify': 'auto',
      }}
      paint={{
        'text-halo-color': '#fff',
        'text-halo-width': 1,
        'text-opacity': 0.5,
      }}
    />
  );
}
