import React, { Component } from 'react';
import { Map as ReactMapboxGL, Source } from 'react-mapbox-gl';
import 'mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as MapboxGeocoder from 'mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import './LvtMap.css';
import { Legend } from './Legend';
import { Slider } from './Slider';
import { CityMenu } from './CityMenu';
import { ChoroplethMenu } from './ChoroplethMenu';
import ChoroplethLabels from './ChoroplethLabels';
import ChoroplethLayer from './ChoroplethLayer';
import TutorialPopup from './TutorialPopup';
import BottomBar from './BottomBar';

const ReactMap = ReactMapboxGL({
  accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
  dragRotate: false,
  pitchWithRotate: false,
});

// For slider function
const sliderIncrements = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const choroplethLayers = [{
  id: 'residentialChoroplethSource',
  name: 'Residential',
},
{
  id: 'commercialChoroplethSource',
  name: 'Commercial',
},
{
  id: 'mixeduseChoroplethSource',
  name: 'Mixed Use',
},
{
  id: 'industrialChoroplethSource',
  name: 'Industrial',
},
{
  id: 'vacantChoroplethSource',
  name: 'Vacant Land',
},
{
  id: 'abatedChoroplethSource',
  name: 'Non-Taxed Buildings',
},
{
  id: 'allChoroplethSource',
  name: 'All Land Uses',
},
];

const INITIAL_SLIDER_POSITION_INDEX = 5;
const CONTIGUOUS_US = {
  zoom: 4,
  center: [-99.0909, 39.8355],
};

export class LvtMap extends Component {
  constructor(props) {
    super(props);
    // this.mapContainerHeight = this.props.height || window.innerHeight;
    this.mapContainerHeight = this.props.height || window.innerHeight;


    const selectedIndex = this.props.selectedCityIndex;
    const selectedCity = selectedIndex !== null ? this.props.cities[selectedIndex] : null;
    this.map = null;
    this.state = {
      zoom: Number(selectedCity.data.zoom),
      center: JSON.parse(selectedCity.data.center),
      selectedChoroplethIndex: 0,
      sliderValue: sliderIncrements[INITIAL_SLIDER_POSITION_INDEX],
      showHelpPopups: false,
    };
  }

  handleCityChange = async (selectedIndex) => {
    this.props.onCityChange(selectedIndex);
    this.removeParcels();
    const selectedCity = this.props.cities[selectedIndex];
    this.addParcels(selectedCity);
    this.setState({
      zoom: Number(selectedCity.data.zoom),
      center: JSON.parse(selectedCity.data.center),
    });
  };

  togglePop = () => {
    this.setState({
      showHelpPopups: !this.state.showHelpPopups,
    });
  };

  render() {
    const selectedIndex = this.props.selectedCityIndex;
    const selectedCity = selectedIndex !== null ? this.props.cities[selectedIndex] : null;
    const selectedReport = this.props.report;
    const selectedChoroplethLayer = choroplethLayers[this.state.selectedChoroplethIndex];
    return (
      <>
        { /* vramirez: uncomment the following to see/debug the component state */}
        { /* <div><pre>{JSON.stringify(this.state.demographics, null, 2)}</pre></div> */}

        <ReactMap
          style="mapbox://styles/robertschalkenbachfoundation/ck75k9l750c7q1ik9sov5llby"
          zoom={[this.state.zoom]}
          center={this.state.center}
          containerStyle={{
            width: '100vw',
            height: '100vh',
          }}
          onStyleLoad={this.initMap}
          onMove={this.onMapMove}
        >

          <Legend />

          <CityMenu
            cities={this.props.cities}
            selectedIndex={this.props.selectedCityIndex}
            onChange={this.handleCityChange}
          />

          <ChoroplethMenu
            choroplethLayers={choroplethLayers}
            selectedIndex={this.state.selectedChoroplethIndex}
            onChange={
              (selectedIndex) => {
                this.setState({ selectedChoroplethIndex: selectedIndex });
              }
            }
          />

          <Slider
            initialValue={sliderIncrements[INITIAL_SLIDER_POSITION_INDEX]}
            onSliderChange={this.handleSliderChange}
          />

          <Source
            id="choroplethSource"
            geoJsonSource={{
              type: 'geojson',
              data: selectedCity.data[selectedChoroplethLayer.id],
            }}
          />

          {
            this.props.selectedCityIndex !== null && sliderIncrements.map((increment) => {
              const dataField = `lt_${increment}_dif`;
              const layerName = `${increment}%`;

              return (
                <ChoroplethLayer
                  layerId={layerName}
                  sourceId="choroplethSource"
                  dataField={dataField}
                  visible={this.state.sliderValue === increment}
                  demographics={this.props.demographics}
                  choroplethType={selectedCity.data.choroplethType}
                />
              );
            })
          }

          <ChoroplethLabels sourceId="choroplethSource" />

          <div className="reportLink">
            <a href={selectedReport} target="_blank">
              View City Summary PDF
            </a>
          </div>

          <div>
            <div className="tutorialBtn" onClick={this.togglePop}>
              <button>
                Toggle Help Windows
              </button>
            </div>
            {this.state.showHelpPopups && (
              <TutorialPopup toggle={this.togglePop} />
            )}
          </div>

          <BottomBar />

        </ReactMap>
      </>
    );
  }

  initMap = (map) => {
    this.map = map;

    const geocoder = new MapboxGeocoder({
      accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
      marker: true,
      mapboxgl: map,
      zoom: 19.5,
    });

    map.addControl(geocoder);

    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

    const selectedCity = this.props.cities[this.props.selectedCityIndex];
    this.addParcels(selectedCity);

    map.on('click', 'parcel', (e) => {
      const parcelProps = e.features[0].properties;
      const taxableLand = parcelProps.taxable_land;
      const taxableBuilding = parcelProps.taxable_building;
      const address = parcelProps.street_address;
      const cityTaxRates = this.props.taxRates;
      const actualTaxRate = cityTaxRates.actual_tax_rate;
      const buildType = parcelProps.building_category;
      console.log(buildType)


      let tableRows = '';
      for (let i = 100; i >= 0; i -= 10) {
        const landRate = i > 0 ? cityTaxRates[`lt_${i}`] : 0;
        const buildingRate = 100 - i > 0 ? cityTaxRates[`bt_${100 - i}`] : 0;
        const taxBill = Math.round((taxableLand * landRate) + (taxableBuilding * buildingRate));
        tableRows += `<tr> <td> ${i}%</td> <td>$ ${taxBill.toLocaleString()}</td> </tr>`;
      }
      const actualTaxBill = Math.round((taxableLand * actualTaxRate) + (taxableBuilding * actualTaxRate));

      if (buildType != 'x') {
        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(`<div><b>Address:</b><br>${address
          }<br> <b>Taxable Land:</b> <br> $${taxableLand.toLocaleString()
          }<br> <b>Taxable Improvements:</b> <br> $${taxableBuilding.toLocaleString()
          }<br> <b>Actual Tax Bill:</b> <br>$${actualTaxBill.toLocaleString()}</div>`
            + `<div><table style='width:100%'><tr><th>% Revenue from Land</th> <th>Tax Bill</th></tr>${
              tableRows
            }</table></div>`)
          .addTo(this.map); }
        else {
          new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(`<div><b>Address:</b><br>${address
          }<br> <b>Taxable Land:</b> <br> $${taxableLand.toLocaleString()
          }<br> <b>Taxable Improvements:</b> <br> $${taxableBuilding.toLocaleString()
          }<br> <b>Actual Tax Bill:</b> <br>$0</div>`)
          .addTo(this.map);
        }
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', 'parcel', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'parcel', () => {
      this.map.getCanvas().style.cursor = '';
    });
  };

  removeParcels = () => {
    const { map } = this;
    ['parcel', 'exempt', 'abated'].forEach((layerName) => {
      if (map.getLayer(layerName)) {
        map.removeLayer(layerName);
      }
    });
    if (map.getSource('parcel_tiles')) {
      map.removeSource('parcel_tiles');
    }
  };

  addParcels = (selectedCity) => {
    const { map } = this;
    map.addSource('parcel_tiles', {
      type: 'vector',
      tiles: [selectedCity.data.parcelSource],
      minzoom: 0,
      maxzoom: Number(selectedCity.data.parcelMaxZoom),
    });

    const layerPrototype = {
      type: 'circle',
      source: 'parcel_tiles',
      'source-layer': selectedCity.data.sourceLayer,
    };
    map.addLayer({
      id: 'parcel',
      ...layerPrototype,
      paint: {
        'circle-radius': ['step', ['zoom'], 0, 15, 3.4, 16, 4.0, 17, 6, 18, 7, 19, 9, 20, 13],
        'circle-color': ['match', ['get', 'building_category'],
          'r', '#fc8d62', // residential
          'c', '#8da0cb', // commercial
          'm', '#bc8dcb', // commercial/residential mixed use
          'i', '#808865', // industrial
          'v', '#d4b78a', // vacant land
          'x', '#05ddcb', // fully exempt 
          '#d92550', // other
        ],
      },
    });
    map.addLayer({
      id: 'exempt',
      ...layerPrototype,
      filter: [
        'all', ['==', ['get', 'taxable_building'], 0],
        ['==', ['get', 'taxable_land'], 0],
      ],
      paint: {
        'circle-radius': ['step', ['zoom'], 0, 15, 2, 16, 2.2, 17, 2.5, 18, 3],
        'circle-color': '#000',
      },
    });

    map.addLayer({
      id: 'abated',
      ...layerPrototype,
      filter: [
        'all', ['!=', ['get', 'building_category'], 'v'],
        ['==', ['get', 'taxable_building'], 0],
        ['>', ['get', 'taxable_land'], 0],
      ],
      paint: {
        'circle-radius': ['step', ['zoom'], 0, 15, 2, 16, 2.2, 17, 2.5, 18, 3],
        'circle-color': '#fff',
      },
    });
  };

  handleSliderChange = (sliderValue) => {
    this.setState({
      sliderValue,
    });
  };

  onMapMove = (map, event) => {
    this.setState((prevState) => ({
      zoom: map.getZoom(),
      center: [map.getCenter().lng, map.getCenter().lat],
    }));
  };
}
